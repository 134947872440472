const state=()=>({
    isVisible:false
})

const getters ={}

const actions ={
    
}

const mutations ={
    showLoader(state){
        state.isVisible=true
    },
    closeLoader(state){
        state.isVisible=false
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}