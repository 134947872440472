import Vue from 'vue'
import VueRouter from 'vue-router'
import isValidToken from '../mixins/isValidToken'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue')
      }
      else{
        return import('../views/Servicios.vue');
      }
    }
  },
  {
    path: '/Usuario',
    name: 'Usuario',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue');
      }
      else{
        return import('../views/Usuario.vue');
      }
    }
  },
  {
    path: '/Logs',
    name: 'Logs',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue');
      }
      else{
        return import('../views/Logs.vue')
      } 
    }
  },
  {
    path: '/Devices',
    name: 'Devices',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue');
      }
      else{
        return import('../views/Devices.vue')
      } 
    }
  },
  {
    path: '/Tickets',
    name: 'Tickets',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue');
      }
      else{
        return import('../views/Tickets.vue')
      } 
    }
  },
  {
    path: '/Notifications',
    name: 'Notifications',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue')
      }
      else{
        return import('../views/notifications.vue')
      }
    }
  },
  {
    path: '/Pedidos',
    name: 'Pedidos',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue')
      }
      else{
        return import('../views/Orders.vue')
      }
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const adminPages = ['/','/Tickets'];
  const adminRequired = !adminPages.includes(to.path)
  const isAdmin = localStorage.getItem('isAdmin')

  if(adminRequired && isAdmin != 1){
    return next('/')
  }
  next();
})

export default router
