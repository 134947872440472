import axios from '../../mixins/axiosInstanceV2.js';
import qs from 'qs';

const state=()=>({
    dialogValuesClient:{
        clientNumber: "",
        company: "",
        rfc: "",
        email: "",
        phoneNumber: "",
        address: ""
    },
    dialogEditClient: false,
    dialogAddClient: false,
    dialogDeleteClient: false,
    dropdownClients:[],
    dropdownClientsList:[],
    dropdownClient: null
})

const getters ={}

const actions ={
    /**
     * * getDropdownClients(): Function(Action)
     * @param {Function} commit Function that allows to dispatch mutations  
     */
    async getDropdownClients({commit}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let data = await axios.instance.get("/client");
        commit('setDropdownClients', data.data.data);
    },

    /**
     * * addClient(): Function(Action)
     * @param {Function} commit Function allows to dispatch mutations
     * @param {Object} state Object that contains vuex application state of current module
     */
    async addClient({commit,state}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/client",qs.stringify({
            client_number:  state.dialogValuesClient.clientNumber,
            company:        state.dialogValuesClient.company.toUpperCase(),
            rfc:            state.dialogValuesClient.rfc,
            email:          state.dialogValuesClient.email,
            phone:          state.dialogValuesClient.phoneNumber,
            address:        state.dialogValuesClient.address,
        }));
        commit('resetValuesDialogClients')
        commit('closeAddClientDialog')
    },

    async returnValuesClient({dispatch,commit},value){
        dispatch('getDropdownClients')
        commit('updateDropdownClient',value.dropdownClient)
    },
    async closeAddClientDialog({commit})
    {
        commit('closeAddClientDialog')
        commit('resetValuesDialogClients')
    }
}

const mutations ={
    /* 
        ! Setters
        * Functions that modify vuex state
     */

    /**
     * *SetDropdownClients(): Function(mutation)
     * @param {Object} state Vuex application state in current module
     * @param {Array} value All database clients array
     */
    async setDropdownClients(state, value){
        state.dropdownClients = value
    },
    
    /**
     * *updateClientNumber(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     * @param {String} value client_number data string
     */
    async updateClientNumber(state, value){
        state.dialogValuesClient.clientNumber = value;
    },

    /**
     * *updateCompany(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     * @param {String} value company data string 
     */

    async updateCompany(state, value){
        state.dialogValuesClient.company = value;
    },

    /**
     * *updateRFC(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     * @param {String} value RFC data string 
     */
    async updateRFC(state, value){
        state.dialogValuesClient.rfc = value;
    },

    /**
     * *updateEmail(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     * @param {String} value Email data string
     */
    async updateEmail(state, value){
        state.dialogValuesClient.email = value;
    },

    /**
     * *updatePhoneNumber(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     * @param {String} value phone data string
     */
    async updatePhoneNumber(state, value){
        state.dialogValuesClient.phoneNumber = value;
    },

    /**
     * *updateAddress(): Function(mutation)
     * @param {Object} state Vuex application state in current module  
     * @param {String} value address data string
     */

    async updateAddress(state, value){
        state.dialogValuesClient.address = value;
    },

    /**
     * *closeAddClientDialog(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     */

    async closeAddClientDialog(state){
        state.dialogAddClient = false
    },

    /**
     * *showAddClientDialog(): Function(mutation)
     * @param {Object} state Vuex application state in current module 
     */
    async showAddClientDialog(state){
        state.dialogAddClient = true
    },
    /**
     * *updateDropdownClient(): Function(mutation)
     * @param {Object} state Vuex application state in current module
     * @param {Object} value dropdownClient object information {name:?,id:?} 
     */
     async updateDropdownClient(state,value){
        if(value.length){
            state.dropdownClientsList=value.map((v)=>{
                return {client_id:v.id}
            })
        }
        
        state.dropdownClient=value
    },
    /* 
        ! Resetters
        * Functions that modify state as initial values
     */

    /**
     * *resetValuesDialogClients(): Function(mutation)
     * @param {Object} state Vuex application state in current module
     */
    async resetValuesDialogClients(state){
        state.dialogValuesClient={
            clientNumber: "",
            company: "",
            rfc: "",
            email: "",
            phoneNumber: "",
            address: ""
        }
    }
    
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}