import axios from "axios";

function environment(version) {
  if (process.env.NODE_ENV === "development") {
    //return `http://localhost:5051/api/${version}`
    return `https://developcrmback.radiotrunk.com/api/${version}`;
  } else if (process.env.NODE_ENV === "production") {
    return `https://productioncrmback.radiotrunk.com/api/${version}`;
  }
}

const instance = (version) => {
  return axios.create({
    baseURL: environment(version),
    timeout: 0,
    headers: { "Content-Type": "application/json" },
  });
};

export default { instance };
