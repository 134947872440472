import axios from "axios";

function environment() {
  if (process.env.NODE_ENV === "development") {
    //return 'http://localhost:5051/api/v2'
    return "https://developcrmback.radiotrunk.com/api/v2";
  } else if (process.env.NODE_ENV === "production") {
    return "https://productioncrmback.radiotrunk.com/api/v2";
  }
}

const instance = axios.create({
  baseURL: environment(),
  timeout: 0,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

export default { instance };
