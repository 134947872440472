import axios from '../../mixins/axiosInstanceV2.js';
import qs from 'qs';
const state=()=>({
    dropdownExternalUsers:[],
    chipsExternalUsers:[],
    dropdownExternalUser:[],
    addExternalUserDialog:false,
    editExternalUserDialog: false,
    deleteExternalUserDialog: false,
    dialogValuesExternalUser: {
        id: null,
        username: "",
        password: "",
        repeatedpassword: "",
    },
    passwordRules: [
        value => !!value || 'Required.',
        v => v.length >= 8 || 'Min 8 characters'
    ],
    externalUsersTable: {
        search: '',
        loading: true,
        ExtUsrs: [],
        headers: [
            {
                text: "ID",
                sortable: true,
                value: "ext_usr_id",
            },
            {
                text: "Usuario",
                sortable: true,
                value: "username",
            },
            {
                text: "Fecha de Creacion",
                sortable: false,
                value: "created",
            },
            {
                text: "Acciones",
                sortable: true,
                value: "acciones",
            },
    ],
    }
})

const getters ={}

const actions ={
    async addExternalUserList({state,commit,rootState,dispatch}){
        const reducer = async (arr)=>arr.reduce(async (acc,el)=>{
            if(el)
              {
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post('/multExtUsers', qs.stringify({
                    ext_usr_id: el.id,
                    device_id: rootState.Devices.UpdateDevices.dialogValuesDevices.deviceID
                }))
              }
            return true
        },false)
        await reducer(state.dropdownExternalUser)
        await dispatch('getExternalUserList')
        await dispatch('Devices/FetchDevices/getAllDevices',null,{root:true})
        await commit('updateDropdownExtUsr',[])
        
    },
    async addExternalUserListToMultipleDevices({state,rootState}){
        const reducer = async (arr)=>arr.reduce(async (acc,el)=>{
            if(el)
              {
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post('/multExtUsers', qs.stringify({
                    ext_usr_id: el.id,
                    device_id: rootState.Devices.UpdateDevices.dialogValuesDevices.deviceID
                }))
              }
            return true
        },false)
        await reducer(state.dropdownExternalUser)
        
    },
    async addExternalUserToNewDevice({state},value)
    {
        const reducer = async (arr)=>arr.reduce(async (acc,el)=>{
            if(el)
              {
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post('/multExtUsers', qs.stringify({
                    ext_usr_id: el.id,
                    device_id: value
                }))
              }
            return true
        },false)
        await reducer(state.dropdownExternalUser)
    },
    async deleteExternalUserList({dispatch,rootState},value){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.delete('/multExtUsers?ext_usr_id='+value.id+'&device_id='+ rootState.Devices.UpdateDevices.dialogValuesDevices.deviceID)
        dispatch('getExternalUserList')
        dispatch('Devices/FetchDevices/getAllDevices',null,{root:true})
    },
    async getExternalUserList({commit,rootState}){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let data=await axios.instance.get('/multExtUsers?id='+rootState.Devices.UpdateDevices.dialogValuesDevices.deviceID)
        commit('updateChipsExternalUsers',data.data.data)
        } catch (error) {
            if(error){
                commit('updateChipsExternalUsers',null)
            }
        }
    },
    async returnValuesExternalUser({dispatch,commit},value){
        dispatch('getExternalUsers')
        commit('updateDropdownExtUsr',value.dropdownExternalUser)
    },
    async getDropdownExternalUsers({commit})
    {
        try{
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            let data = await axios.instance.get("/extUsrs");
            commit('updateDropdownExtUsrs',data.data.data)
        }catch(error){
            if(error){
                alert('Hubo un error al obtener usuarios')
            }
        }
    },
    async getTableExternalUsers({commit}){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var response=await axios.instance.get("/extUsrs?table=true")
            var data=response.data.data
            commit('setExternalUsersTable', data)
            commit('closeLoader')
        } catch (error) {
            if(error){
                alert('Hubo un error al obtener los usuarios')
            }
        }
    },
    async generateRandomPassword({commit}){
        try {
            let randomstring = Math.random().toString(36).slice(-8);
            commit('setPassword', randomstring)
            commit('setRepeatedPassword', randomstring)
        } catch (error) {
            if(error){
                alert('Hubo un error al crear el password')
            }
        }
    },
    async addExternalUser({state, commit, dispatch}){
        try {
            if(state.dialogValuesExternalUser.password === state.dialogValuesExternalUser.repeatedpassword){
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post('/extUsrs/create', qs.stringify({
                    username: state.dialogValuesExternalUser.username,
                    password: state.dialogValuesExternalUser.password
                }))
                commit('closeExternalUserAddDialog')
                dispatch('getTableExternalUsers')
                commit('resetValuesDialogExternalUser')
            }
            else{
                alert('Los passwords deben de ser iguales')
            }
        } catch (error) {
            alert('No se pudo crear el usuario externo')
        }
    },
    async updateExternalUser({state,commit,dispatch}){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.put('/extUsrs/'+state.dialogValuesExternalUser.id,qs.stringify({
                password:state.dialogValuesExternalUser.password,
                username:state.dialogValuesExternalUser.username.toUpperCase()
            }))
            commit('closeExternalUserEditDialog')
            dispatch('getTableExternalUsers')
            commit('resetValuesDialogExternalUser')
        } catch (error) {
            if(error){
                alert("No se pudo actualizar el usuario externo")
            }
        }
    },
    async deleteExternalUser({state,commit,dispatch}){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.delete('/extUsrs/'+state.dialogValuesExternalUser.id)
            commit('closeExternalUserDeleteDialog')
            dispatch('getTableExternalUsers')
            commit('resetValuesDialogExternalUser')
        } catch (error) {
            if(error){
                alert("No se pudo eliminar el usuario externo");
            }
        }
    }
}

const mutations ={
    async updateChipsExternalUsers(state,value){
        state.chipsExternalUsers=value
    },
    async updateDropdownExtUsr(state,value){
        state.dropdownExternalUser=value
    },
    async updateDropdownExtUsrs(state,value){
        state.dropdownExternalUsers=value
    },
    async setUsername(state, value){
        state.dialogValuesExternalUser.username = value
    },
    async setPassword(state, value){
        state.dialogValuesExternalUser.password = value
    },
    async setRepeatedPassword(state, value){
        state.dialogValuesExternalUser.repeatedpassword = value
    },
    async closeExternalUserAddDialog(state){
        state.addExternalUserDialog = false
    },
    async showExternalUserAddDialog(state){
        state.addExternalUserDialog = true
    },
    async closeExternalUserEditDialog(state){
        state.editExternalUserDialog = false
    },
    async showExternalUserEditDialog(state, value){
        state.dialogValuesExternalUser.username = value.username
        state.dialogValuesExternalUser.id = value.ext_usr_id
        state.editExternalUserDialog = true
    },
    async closeExternalUserDeleteDialog(state){
        state.deleteExternalUserDialog = false
    },
    async showExternalUserDeleteDialog(state, value){
        state.dialogValuesExternalUser.username = value.username
        state.dialogValuesExternalUser.id = value.ext_usr_id
        state.deleteExternalUserDialog = true
    },
    async resetValuesDialogExternalUser(state){
        state.dialogValuesExternalUser = {
            username: "",
            password: "",
            repeatedpassword: ""
        };
    },
    async setExternalUsersTable(state,value){
        state.externalUsersTable.ExtUsrs = value
    },
    async closeLoader(state){
        state.externalUsersTable.loading = false
    },
    async setTableSearchTerm(state, value){
        state.externalUsersTable.search = value
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}