import axios from '../../mixins/axiosInstance'
import qs from 'qs';

const state = () => ({
    internalUsersTable: {
        search: '',
        loading: true,
        usersTable: [],
        headers: [
            {
                text: "ID",
                sortable: true,
                value: "sys_usrs_id",
            },
            {
                text: "Correo",
                sortable: true,
                value: "email",
            },
            {
                text: "Nombre",
                sortable: true,
                value: "name",
            },
            {
                text: "Fecha de Creacion",
                sortable: false,
                value: "created",
            },
            {
                text: "Administrador",
                sortable: true,
                value: "isAdmin",
            },
            {
                text: "Acciones",
                sortable: false,
                value: "acciones",
            }
        ],
    },
    manageSettingsDialog:{
        dialog: false,
        oldPassword: '',
        newPassword: '',
        passwordRules: [
            value => !!value || 'Required.',
            v => v.length >= 8 || 'Min 8 characters'
        ],
    },
    editInternalUserDialog:{
        dialog: false,
        id: '',
        email: '',
        username: '',
        isAdmin: '',
        password: '',
        repeatedPassword: '',
        passwordRules: [
            value => !!value || 'Required.',
            v => v.length >= 8 || 'Min 8 characters'
        ],
    },
    deleteInternalUserDialog:{
        dialog: false,
        id: '',
        email: '',
        name: ''
    },
    addInternalUserDialog: {
        dialog: false,
        email: '',
        name: '',
        password: '',
        isAdmin: false
    }
})
const getters = {}

const actions = {
    async fetchInternalUserTable({ commit }){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var response=await axios.instance.get("/adminUser")
            var data=response.data.data
            for(let i=0;i<data.length;i++)
            {
                if(data[i].isAdmin===0)
                {
                    data[i].isAdmin='No'
                }else
                {
                    data[i].isAdmin='Si'
                }
            }
            commit('setInternalUsersTable', data)
            commit('closeLoader')
        } catch (error) {
            if(error){
                alert('No se pudieron obtener los datos de usuarios internos')
            }
        }
    },
    async changePassword({ state, rootState, commit }){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.patch('/User?email='+rootState.Navbar.navbarMenu.email, qs.stringify({
            oldpassword: state.manageSettingsDialog.oldPassword,
            newpassword: state.manageSettingsDialog.newPassword
        }))
        .then(function(response){
            if(response.data.data){
                if(!alert(response.data.data)){
                    window.location.reload()
                }
            }
        }).catch(function(error){
            if(error.response.data.error){
                alert(error.response.data.error)
                commit('resetManageSettingsDialoog')
            }
            else{
                alert('Error has occurred')
            }
        })
    },
    async editInternalUser({ commit, dispatch, state }){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            if(state.editInternalUserDialog.password===state.editInternalUserDialog.repeatedPassword)
            {
                await axios.instance.patch("/adminUser/"+state.editInternalUserDialog.id,qs.stringify({
                    isAdmin: state.editInternalUserDialog.isAdmin,
                    Pass: state.editInternalUserDialog.password
                }))
                alert('Usuario modificado')
                dispatch('fetchInternalUserTable')
                commit('resetEditInternalUserDialog')
            }
            else{
                alert('Las contraseñas tienen que ser iguales')
            }
        } catch (error) {
            if(error){
                alert('Ocurrió un error al editar un usuario')
            }
        }
    },
    async deleteInternalUser({ commit, dispatch, state }){
        await axios.instance.delete("/adminUser/"+state.deleteInternalUserDialog.id)
        alert('Usuario eliminado')
        dispatch('fetchInternalUserTable')
        commit('closeDeleteInternalUserDialog')
        commit('resetDeleteInternalUserDialog')
    },
    async addInternalUser({ commit, dispatch, state }){
        try {
            if(state.addInternalUserDialog.email!=''||state.addInternalUserDialog.password!=''||state.addInternalUserDialog.name!='')
            {
                if(state.addInternalUserDialog.isAdmin===true)
                {
                    axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                    await axios.instance.post("/adminUser",qs.stringify({
                        email:state.addInternalUserDialog.email,
                        name:state.addInternalUserDialog.name,
                        password:state.addInternalUserDialog.password
                    }))
                    dispatch('fetchInternalUserTable')
                    commit('closeAddInternalUserDialog')
                    commit('resetAddInternalUserDialog')
                }else{
                    axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                    await axios.instance.post("/User",qs.stringify({
                        email:state.addInternalUserDialog.email,
                        name:state.addInternalUserDialog.name,
                        password:state.addInternalUserDialog.password
                    }))
                    dispatch('fetchInternalUserTable')
                    commit('closeAddInternalUserDialog')
                    commit('resetAddInternalUserDialog')
                }
            }else{
                alert('Todos los campos son obligatorios')
            }
        } catch (error) {
            if(error){
                alert('Ocurrió un error al agregar un usuario interno')
            }
        }
    }
}
const mutations = {
    async setInternalUsersTable(state, value){
        state.internalUsersTable.usersTable = value
    },
    async closeLoader(state){
        state.internalUsersTable.loading = false
    },
    async setTableSearchTerm(state, value){
        state.internalUsersTable.search = value
    },
    async showManageSettingDialog(state){
        state.manageSettingsDialog.dialog = true
    },
    async closeManageSettingDialog(state){
        state.manageSettingsDialog.dialog = false
    },
    async setNewPassword(state, value){
        state.manageSettingsDialog.newPassword = value
    },
    async resetManageSettingsDialoog(state){
        state.manageSettingsDialog = {
            dialog: false,
            oldPassword: '',
            newPassword: '',
            passwordRules: [
                value => !!value || 'Required.',
                v => v.length >= 8 || 'Min 8 characters'
            ],
        }
    },
    async setOldPassword(state, value){
        state.manageSettingsDialog.oldPassword = value
    },
    async showEditInternalUserDialog(state, value){
        state.editInternalUserDialog.id = value.sys_usrs_id
        state.editInternalUserDialog.dialog = true;
        state.editInternalUserDialog.username = value.name
        state.editInternalUserDialog.email = value.email
        if(value.isAdmin === 'Si'){
            state.editInternalUserDialog.isAdmin = '1'
        }
        else{
            state.editInternalUserDialog.isAdmin = '0'
        }
    },
    async closeEditInternalUserDialog(state){
        state.editInternalUserDialog.dialog = false
    },
    async setNewPasswordEditInternalUserDialog(state,value){
        state.editInternalUserDialog.password = value
    },
    async setRepeatedPasswordEditInternalUserDialog(state, value){
        state.editInternalUserDialog.repeatedPassword = value
    },
    async setIsAdminEditInternalUserDialog(state, value){
        state.editInternalUserDialog.isAdmin = value
    },
    async resetEditInternalUserDialog(state){
        state.editInternalUserDialog = {
            dialog: false,
            email: '',
            username: '',
            isAdmin: '',
            password: '',
            repeatedPassword: '',
            passwordRules: [
                value => !!value || 'Required.',
                v => v.length >= 8 || 'Min 8 characters'
            ],
        }
    },
    async showDeleteInternalUserDialog(state, value){
        state.deleteInternalUserDialog.id = value.sys_usrs_id
        state.deleteInternalUserDialog.email = value.email
        state.deleteInternalUserDialog.name = value.name
        state.deleteInternalUserDialog.dialog = true
    },
    async closeDeleteInternalUserDialog(state){
        state.deleteInternalUserDialog.dialog = false
    },
    async resetDeleteInternalUserDialog(state){
        state.deleteInternalUserDialog = {
            dialog: false,
            id: '',
            email: '',
            name: ''
        }
    },
    async setAddInternalUserDialogName(state,value){
        state.addInternalUserDialog.name = value
    },
    async setAddInternalUserDialogEmail(state,value){
        state.addInternalUserDialog.email = value
    },
    async setAddInternalUserDialogPassword(state, value){
        state.addInternalUserDialog.password = value
    },
    async setAddInternalUserDialogIsAdmin(state){
        state.addInternalUserDialog.isAdmin = !state.addInternalUserDialog.isAdmin
    },
    async showAddInternalUserDialog(state){
        state.addInternalUserDialog.dialog = true
    },
    async closeAddInternalUserDialog(state){
        state.addInternalUserDialog.dialog = false
    },
    async resetAddInternalUserDialog(state){
        state.addInternalUserDialog = {
            dialog: false,
            id: '',
            email: '',
            name: '',
            password: '',
            isAdmin: null
        }
    },
}   

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}