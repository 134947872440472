import Vue from 'vue'
import Vuex from 'vuex'
import table from './modules/table'

import TableUsers from './modules/tableUsers'


import notifications from './modules/notifications.js'
import reactivateAccount from './modules/reactivateAccount'

import TableLogs from './modules/TableLogs'
import TableTickets from './modules/TableTickets'
import moduloSpare from './modules/moduloSpare'

import Spares from './modules/Spares'
import Login from './modules/login'
import Devices from './modules/devices/Devices'
import Sims from './modules/sims/Sims'
import Marks from './modules/Marks'
import Models from './modules/Models'
import ExternalUsers from './modules/ExternalUsers'
import Clients from './modules/Clients'
import Snackbars from './modules/Snackbars'
import Navbar from './modules/Navbar'
import InternalUsers from './modules/InternalUsers'
import Tickets from './modules/Tickets'
import Services from './modules/Services'
import ArriveImages from './modules/ArriveImages'
import ReturnImages from './modules/ReturnImages'
import Loader from './modules/Loader'
import Reports from './modules/Reports'
import Orders from './modules/Orders'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        Sims,
        Loader,
        Devices,
        Tickets,
        Login,
        Marks,
        Models,
        ExternalUsers,
        Clients,
        Snackbars,
        Navbar,
        InternalUsers,
        Spares,
        Services,
        Reports,
        ArriveImages,
        ReturnImages,
        Orders,

        moduloSpare,
        table,
        TableTickets,
        TableLogs,
        TableUsers,
        notifications,
        reactivateAccount,
        
    },
    strict: debug,
  
})
